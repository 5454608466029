body {
  margin: 0;
  padding: 0;
  font-family: "Inter", "Montserrat" sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --color-primary: #d9d9d9;
  --color-secondary: #c2b8b6;
  --color-back: rgba(141, 128, 120, 0.25);
  --color-header: #8d8078;
  --color-arrow: #f1f1f1;
  --color-background: #adadad;

  --color-text1: #161515;
  --color-text2: #090909;
  --color-text3: #3d3d3d;

  --color-white: #ffffff;
  --color-black: #000000;

  --font-primary: "Inter", sans-serif;
  --font-secondary: "Montserrat", sans-serif;

  /* --font-size-small: 12px;
  --font-size-medium: 16px;
  --font-size-large: 24px;
  --font-size-xl: 32px; */
}
